.blog {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-image: url("../../assets/images/landing-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 2rem;
  margin-bottom: 3rem;
  justify-content: center;
}
.blog h2,
.blog-intro,
.blog-grid {
  font-family: var(--poppins-font);
}

.blog h2,
.blog-grid h3 {
  color: var(--white);
}

.blog h2 {
  font-size: 4rem;
}
.blog-intro {
  color: var(--whitish-gray);
  font-size: 1.3rem;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 3rem;
}
/*** Blog grid ***/

.blog-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
}
.blog-grid h3 {
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 400;
}

/*** Blog grid item ***/
.blog-grid-item {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.blog-grid-item img {
  max-width: 100%;
  height: 10rem;
  object-fit: cover;
}

.blog-grid-item h3 {
  transition: all ease-in-out 0.3s;
}
.blog-grid-item .credentials {
  color: var(--whitish-gray);
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.blog-grid-item .intro {
  color: var(--whitish-gray);
}

/*** Pagination ***/

.blog .blog-pagination {
  position: initial;
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

/*** Responsive ***/

@media (hover: hover) {
  .blog-grid-item h3:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.3s;
  }
}

@media screen and (min-width: 700px) {
  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1110px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
