.menu-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.menu-modal {
  background: white;
  /* border-radius: 12px; */
  width: 40rem;
  max-width: 100vw;
  max-height: 100vh;
  /* height: 68%; */
  margin: 1rem;
  position: relative;
  color: #333;
}

.close-modal {
  position: fixed;
  right: 0.5rem;
  top: 0.1rem;
  background: none;
  border: none;
  font-size: 6rem;
  color: var(--pinked);
  cursor: pointer;
  z-index: 1001;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.modal-content {
  padding: 0;
}

.modal-image {
  width: 100%;
  height: 38vh;
  object-fit: cover;
  /* border-radius: 12px 12px 0 0; */
}

.modal-info {
  padding: 24px;
}

.modal-info h2 {
 
  margin: 0 0 12px 0;
 
}

.modal-description {
  color: #666;
  margin-bottom: 24px;
  font-size: 0.8rem;
}

.modal-details {
  margin-bottom: 24px;
}

.modal-divider {
  border: none;
  border-top: 2px solid #6e6e6e;
  margin: 20px 0;
}

.weight {
  color: #333333;
  font-size: 1.2rem;
  margin-bottom: 16px;
  font-family: var(--josefin-font);
}

.nutrition-info p {
  color: #666;
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.nutrition-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.nutrition-item {
  /* text-align: center; */
}

.nutrition-item span:first-child {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  display: block;
  margin-bottom: 4px;
}

.nutrition-item span:last-child {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  display: block;
  margin-bottom: 4px;
}

.modal-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.price-num {
  font-size: 1.2rem;
  font-weight: 600;
}

.price-num span {
  font-size: 1.1rem;
} 