/*** Cart ***/
.cart {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  min-height: 61vh;
  padding-bottom: 10rem;
  background-color: var(--light-black); 
  color: var(--white);
  font-family: var(--poppins-font);
  
}

.cart h2 {
  margin: 0 auto;
  font-size: 3rem;
  font-family: var(--josefin-font);
  padding-top: 3vh;
}

.cart-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  text-align: center;
  padding: 1rem;
  align-items: center;
  max-width: 90vw;
  width: 40rem;
}

.cart-content p {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  color: var(--black);
}

/*** Cart item ***/
.cart-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.cart-item img {
  max-width: 90vw;
  object-fit: cover;
}
.cart-item-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cart-item-title {
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-font);
  font-weight: 400;
  color: var(--black);
  text-transform: capitalize;
  font-size: 1.2rem;
  padding-bottom: 1rem;
}
.cart-item-interaction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
  align-items: center;
}
.cart-item-interaction .cart-item-price {
  color: var(--pizza-yellow);
    /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
    font-weight: 700;
}
.cart-item-add-qty {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  color: var(--white);
  opacity: 0.7;
}
.cart-item-add-qty button {
  border: 1px solid rgba(0, 0, 0, 0.113);
  color: var(--black);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 2rem;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  transition: all ease-in-out 0.3s;
}
 
.cart-item-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

/*** Cart totals ***/
.cart-carttotals .cart-totals {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--white);
  max-width: 90vw;
  margin: 0 auto;
  padding: 2rem;
}

.cart-carttotals .totals-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  text-transform: uppercase;
  font-size: 1.2rem;
}
.totals-content > * {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cart-carttotals .totals-content h4 {
  font-weight: 300;
}
.cart-carttotals .cart-totals .totals-content p {
  color: var(--pizza-yellow);
    /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
    font-weight: 700;
}

.order-btn {
  border: 1px solid transparent;
  color: var(--white);
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  transition: all ease-in-out 0.3s;
  width: 100%;
  margin: 0 auto;
  margin-top: 5%;
  font-family: var(--poppins-font);
  transition: all ease-in-out 0.3s;
  border-radius: 5px;
  background-color: var(--yellow);
  text-align: center;
}

.cart-interaction-btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.back-to-menu {
  cursor: pointer;
  border-radius: 30px;
  /* border-style: solid; */
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  /* opacity: 0.8; */
  background-color: var(--light-black-top);
  border: 2px solid var(--light-black-top);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--josefin-font);
}

/* CLEAR CART */
.cart-clear-btn {
  color: var(--whitish-gray);
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-family: var(--josefin-font);
  width: max-content;
  text-align: right;
  letter-spacing: 1px;
  transition: all ease-in-out 0.3s;
}

@media (hover: hover) {
  .cart-item-add-qty button:hover {
    color: var(--yellow);
    border-color: var(--yellow);
    transition: all ease-in-out 0.3s;
  }
  .cart-clear-btn:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.3s;
  }
  .order-btn:hover {
    opacity: 0.7;
    transition: all ease-in-out 0.3s;
  }
  .back-to-menu:hover {
    color: var(--pizza-red);
    transition: all ease-in-out 0.3s;
  }
}

@media screen and (min-width: 700px) {
  .cart-item {
    flex-direction: row;
  }
  .cart-item img {
    max-width: 50%;
  }
  .cart-item-content {
    justify-content: space-between;
  }
  .cart-item-interaction {
    flex-direction: row-reverse;
  }
  .cart-carttotals .cart-totals .totals-content {
    flex-direction: row;
    padding: none;
  }
  .totals-content > * {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}

@media screen and (min-width: 1025px) {
  .cart-item-interaction {
    gap: 2rem;
  }
  .cart-checkout-btn,
  .cart-backtomenu-btn {
    font-size: 1.4rem;
  }
}
