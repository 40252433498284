.choose-payment {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    max-width: 700px;
}
.choose-payment input {
    color: var(--whitish-gray);
    font-family: var(--poppins-font);
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid var(--light-gray);
    padding: 1rem;
    background-color: transparent;
    font-weight: 400;
    font-size: 1rem;
    width: 80%;
}
.payment-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--light-gray);
}
.success-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    margin: 0 auto;
    width: 90%;
    max-width: 700px;
    margin-top: 4rem;
    text-align: center;
}
.success-payment-title {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.success-payment-title h2 {
    color: var(--green);
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 1px;
}
.payment-details h3 {
    color: var(--green);
}
.success-payment-title p {
    color: var(--light-gray);
}
.success-payment img {
    max-width: 5%;
    border: 2px solid var(--green);
    border-radius: 50%;
    padding: 1rem;
}
.cvv-input,
.date-input {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.credit-card-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: left;
}

.cvv-exp {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 80%;
}
.cvv {
    width: 5rem;
}
.expiration-date {
    display: flex;
    flex-direction: row;
}
.expiration-date input:first-child {
    border-right: none;
    width: 1.7rem;
}
.expiration-date input {
    outline: none;
    display: flex;
    flex-direction: column;
}
.expiration-date input:last-of-type {
    border-left: none;
    width: 1.5rem;
}

.success-payment-redirection {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.success-payment-redirection a:last-child {
    text-decoration: underline;
    color: var(--whitish-gray);
}
.success-payment-redirection a:last-child:hover {
    color: var(--yellow);
    transition: var(--transition);
}

.card {
    width: 400px;
    height: 250px;
}

.front,
.back {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 15px;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-image: linear-gradient(to right, #111, #555);
    overflow: hidden;
}
.front {
    transform: translateZ(0);
}
.strip-bottom,
.strip-top {
    position: absolute;
    right: 0;
    height: inherit;
    background-image: linear-gradient(to bottom, var(--pizza-red-light), var(--pizza-red));
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
}
.strip-bottom {
    width: 200px;
    transform: skewX(-15deg) translateX(50px);
}
.strip-top {
    width: 180px;
    transform: skewX(20deg) translateX(50px);
}
.chip {
    position: relative;
    top: 60px;
    left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
    overflow: hidden;
}
.chip .chip-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #333;
}
.chip .chip-line:nth-child(1) {
    top: 13px;
}
.chip .chip-line:nth-child(2) {
    top: 20px;
}
.chip .chip-line:nth-child(3) {
    top: 28px;
}
.chip .chip-line:nth-child(4) {
    left: 25px;
    width: 1px;
    height: 50px;
}
.chip .chip-main {
    width: 20px;
    height: 25px;
    border: 1px solid #333;
    border-radius: 3px;
    background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
    z-index: 1;
}
.wave {
    position: relative;
    top: 20px;
    left: 100px;
}
.card-number {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 25px 10px;
    font-size: 23px;
    font-family: "cc font", monospace;
}
.end {
    margin-left: 25px;
    text-transform: uppercase;
    font-family: "cc font", monospace;
}
.end .end-text,
.cvv-text {
    font-size: 9px;
    color: rgba(255, 255, 255, 0.8);
}
.card-holder {
    margin: 10px 25px;
    text-transform: uppercase;
    font-family: "cc font", monospace;
}

.master {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
}
.master .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.master .master-red {
    background-color: #eb001b;
}
.master .master-yellow {
    margin-left: -10px;
    background-color: rgba(255, 209, 0, 0.7);
}
.card {
    perspective: 1000;
}
.back {
    transform: rotateY(180deg) translateZ(0);
    background: #9e9e9e;
}
.back .strip-black {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 50px;
    background: #000;
}
.back .ccv {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    height: 36px;
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    border-radius: 5px;
    text-align: right;
    letter-spacing: 1px;
    color: #000;
    background: #fff;
}
.back .ccv label {
    display: block;
    margin: -30px 0 15px;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
}
.back .terms {
    position: absolute;
    top: 150px;
    padding: 20px;
    font-size: 10px;
    text-align: justify;
}
