li {
  list-style: none;
  /* border-bottom: 1px solid var(--yellow); */
}
.menu-main::after {
  /* content: "";
  height: 10rem; */
}
.menu-main {
  display: grid;
  grid-template-columns: 1fr;
  /* background-image: var(--bm); */
  /* background-attachment: fixed; */
  padding-bottom: 4rem;
  max-width: 1440px;
  margin: 0 auto;
  justify-items: center;
  /* background-color: var(--light-black-top); */
  padding-top: 8vh;
}
.side-menu h1 {
  font-family: var(--josefin-font);
  text-align: left;
}
.side-menu {
  grid-column: span 1;
  background-color: transparent;
  color: var(--white);
  width: 100%;
  justify-content: space-around;
  margin-top: 2rem;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Menu Search */

.menu-search-section {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: 2rem;
  margin-bottom: 4rem;
  position: relative;
}
.menu-search {
  width: 80%;
  display: block;
  background-color: transparent;
  border: 2px solid transparent;
  /* opacity: 0.6; */
  border-radius: 20px;
  outline: none;
  width: -webkit-fill-available;
  
}
.menu-search2 {
  width: 80%;
  display: block;
  background-color: transparent;
  /* border: 2px solid var(--green); */
  /* opacity: 0.6; */
  border-radius: 20px;
  outline: none;
  width: -webkit-fill-available;
  position: relative;
  color: var(--white);
  font-family: var(--josefin-font);
  font-size: 1rem;
  padding: 1rem;
  background: var(--light-gray);
  
}
.menu-search::placeholder {
  color: var(--white);
  font-family: var(--josefin-font);
  font-size: 1rem;
}
.menu-search[type="text"] {
  color: var(--black);
  font-family: var(--poppins-font);
  font-size: 1.3rem;
  padding: 1rem;
  background: var(--light-gray);
}
.menu-search-icon {
  cursor: pointer;
  position: absolute;
  display: block;
  top: 40%;
  right: 6%;
  filter: brightness(0) saturate(100%) invert(80%) sepia(5%) saturate(2171%) hue-rotate(308deg) brightness(86%) contrast(87%);
  width: 10%;
}

.sale-message {
  position: absolute;
  top: 0;
  text-transform: uppercase;
  color: var(--pizza-red);
  font-family: var(--poppins-font);
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 2px;
  padding: 1rem;
  text-shadow: 1px 1px var(--light-black);
}

/* ScrollBar */

.side-menu ul::-webkit-scrollbar {
  width: 1em;
}

.side-menu ul::-webkit-scrollbar-track {
  box-shadow: transparent;
}

.side-menu ul::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
}

.side-menu ul {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 1.3rem;
  letter-spacing: 1px;
  text-align: center;
  align-items: center;
  padding: 1rem;
  /* border: 2px solid var(--yellow); */
  font-family: var(--poppins-font);
  overflow-x: auto;
  scroll-behavior: smooth;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--lightish-black);
}

.side-menu ul a {
  color: var(--black);
  border-radius: 30px;
  background-color: var(--light-gray);
  border: 2px solid var(--whitish);
  padding: 6px 14px 6px;
}

.side-menu ul a:hover {
  background-color: var(--green);
  color: var(--light-black-top);
  border: 2px solid var(--green);
}

.side-menu ul a {
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.menu-grid {
  display: grid;
  grid-column: span 1;
  grid-template-columns: repeat(2, 1fr);
  /* width: 80%; */
  margin: 0 auto;
  gap: 1rem;
  border-radius: 30px;
  background-color: #ffff;
}
.pagination {
  color: var(--white);
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-top: 3rem;
  visibility: hidden;
}
.pagination li {
  cursor: pointer;
}

.pagination li.selected {
  color: var(--pizza-red);
}
/* MENU ITEM */
.menu-grid-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 5%;
  /* height: 100%; */
  /* justify-content: space-around; */
  position: relative;
  max-width: 45vw;
  gap: 1vw;
  /* justify-content: space-between; */
  border: 4px solid transparent;
  background-color: var(--light-black);
  color: var(--black);
  /* Добавляем свойство для выравнивания по вертикали */
  justify-content: space-between;
  max-height: 26rem;
}
.menu-item-link {
  max-width: 100%;
  height: 15rem;
  max-height: 70%;
  object-fit: cover;
}
.menu-item-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.menu-grid-item h3 {
  font-size: 1.3rem;
  font-family: var(--poppins-font);
  font-weight: 400;
  letter-spacing: 1px;
  align-self: center;
}
/* MENU ITEM ATTRIBUTES */
.menu-grid-item .attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-grid-item .attributes li {
  border: 1px solid rgba(255, 255, 255, 0.113);
  opacity: 0.7;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 0.5rem;
}
.price {
  display: grid;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.3rem;
}
.price-num {
  /* color: var(--pizza-yellow); */
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
  font-weight: 700;
  font-family: var(--josefin-font);
}
.sale-pricing {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.price-num-before {
  color: var(--pizza-red);
  text-decoration: line-through;
}

.add-to-cart-buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.add-to-cart-value {
  font-size: 1.5rem;
  color: var(--whitish-gray);
}
 
.add-to-cart-buttons button {
  cursor: pointer;
  font-size: 2rem;
  color: var(--whitish-gray);
  background-color: transparent;
  border: 1px solid transparent;
  transition: all ease-in-out 0.2s;
  text-align: center;
}

.price {
  display: grid;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  justify-items: center;
}
.price-num {
 
}

.nothing-found {
  color: var(--white);
  margin-top: 3rem;
}

@media (hover: hover) {
  .add-to-cart-buttons button:hover {
    color: var(--yellow);
    transition: all ease-in-out 0.2s;
  }
}

/***************  RESPONSIVE ***************/
@media screen and (min-width: 700px) {
  .menu-main {
    grid-template-columns: repeat(3, 1fr);
    gap: none;
    padding-top: 8vh;
  }

  .side-menu {
    grid-column: span 1;
    position: sticky;
    /* top: 15%; */
    display: flex;
    flex-direction: column;
    height: max-content;
    overflow-x: initial;
    scroll-behavior: initial;
    overflow-y: hidden;
    /* align-items: initial; */
  }
  .menu-search-section {
    width: 100%;
  }
  .side-menu ul {
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
    line-height: 3rem;
    overflow-x: initial;
    scroll-behavior: initial;
  }

  .side-menu ul li a {
    /* color: var(--white); */
    transition: all ease-in-out 0.3s;
  }

  .side-menu ul li a:hover {
    /* color: var(--pizza-red); */
    transition: all ease-in-out 0.3s;
  }

  .side-menu li {
    /* border-bottom: 1px solid var(--yellow); */
  }
  .side-menu li:last-of-type {
    border-bottom: none;
  }
  .menu-grid {
    grid-column: span 2;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 95%;
  }
  .pagination {
    position: absolute;
    bottom: 1%;
    /* right: 30%; */
  }
}

@media screen and (min-width: 90%) {
  .menu-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .side-menu {
    width: 80%;
    overflow-y: hidden;
  }
  .side-menu ul {
    padding: 0;
  }
  .menu-main {
    position: relative;
  }
  .pagination {
    margin: initial;
  }
  .pagination li.selected {
    color: var(--pizza-red);
  }
}
