@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap'); */


/* @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@500&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Tektur:wght@700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap'); */


/* @import url('https://fonts.googleapis.com/css2?family=Rubik+Broken+Fax&display=swap'); */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

 
   /* darktheme */
/* :root {
  font-size: clamp(0.75rem, 2vw, 1.25rem);
    --poppins-font: "Oswald";
    --josefin-font: "Rubik Doodle Shadow";
    --nothing-font: "Rubik Broken Fax";
    --white: #ffffff;
    --light-gray: #faeee1;
    --whitish-gray: #f6ddc4;
    --light-black: rgba(122, 69, 71,0.62);
    --lightish-black: rgba(59, 88, 101,0.62);
    --pizza-yellow: #ef914d;
    --yellow: #bfac74;
    --green: #45b4b4;
    --pizza-red: #c5373a;
    --pizza-red-light: #a55658 0;
    --transition: all ease-in-out 0.3s;
    --red: #c5373a;
    --modal-glass-bg: rgb(59, 88, 101,0.32);
    --modal-box-shadow: -2px 10px 24px -11px #0000009e;
    --transparent-button: #e94049;
    --bi: linear-gradient( 154deg, hsl(31deg 72% 76%) 0%, hsl(15deg 32% 57%) 16%, hsl(359deg 27% 38%) 20%, hsl(359deg 12% 21%) 30%, hsl(0deg 0% 0%) 74%);
    --bm: radial-gradient(circle at 50% -20.71%, #fd831e 0, #f7722a 12.5%, #ec5f33 25%, #db4b37 37.5%, #c53639 50%, #ae2239 62.5%, #980e38 75%, #860038 87.5%, #760039 100%);
} */

      /* midletheme */
      /* :root {
      font-size: clamp(0.75rem, 2vw, 1.25rem);
    --poppins-font: "Oswald";
    --josefin-font: "Rubik Doodle Shadow";
    --nothing-font: "Rubik Broken Fax";
    --white: #073701;
    --light-gray: #250d16;
    --whitish-gray: #073701;
    --light-black: rgb(217, 136, 75,0.62);
    --lightish-black: rgb(194, 75, 57,0.62);
    --pizza-yellow: #073701;
    --yellow: #4c5a65;
    --green: #3db4c6;
    --pizza-red: #635b58;
    --pizza-red-light: #c24b39 0;
    --transition: all ease-in-out 0.3s;
    --red: #c24b39;
    --modal-glass-bg: rgb(253 244 239 / 38%);
    --modal-box-shadow: -2px 10px 24px -11px #0000009e;
    --transparent-button: #b4a49a;
    --bi: linear-gradient( 60deg, hsl(26deg 65% 57%) 0%, hsl(19deg 61% 54%) 19%, hsl(13deg 57% 51%) 27%, hsl(9deg 50% 53%) 34%, hsl(11deg 32% 66%) 41%, hsl(15deg 15% 79%) 47%, hsl(343deg 7% 81%) 53%, hsl(286deg 8% 70%) 59%, hsl(226deg 10% 59%) 66%, hsl(252deg 10% 54%) 73%, hsl(308deg 9% 49%) 81%, hsl(7deg 8% 45%) 100%);
    --bm: radial-gradient(circle at 50% -20.71%, #f4e5ba 0, #ead7b3 12.5%, #dcc8ac 25%, #cab7a4 37.5%, #b3a59a 50%, #9b9490 62.5%, #858686 75%, #717a7d 87.5%, #5f7074 100%);
      } */


      /* ligttheme */
/* :root {
  font-size: clamp(0.75rem, 2vw, 1.25rem);
  --poppins-font: "Oswald";
  --josefin-font: "Merriweather";
  --nothing-font: "Merriweather";
  --white: #15130d;
  --light-gray: #2c271b;
  --whitish-gray: #513b6e;
  --light-black: rgb(249 232 197 / 64%);
  --lightish-black: rgb(146 243 212 / 38%);
  --pizza-yellow: #472059;
  --yellow: #445d5b;
  --green: #a0e0da;
  --pizza-red: #d393f3;
  --pizza-red-light: #e6fdf5 0;
  --transition: all ease-in-out 0.3s;
  --red: #6c0c2b;
  --modal-glass-bg: rgb(146 243 212 / 38%);
  --modal-box-shadow: -2px 10px 24px -11px #0000009e;
  --transparent-button: #f1f4f4;
  --bi: radial-gradient(circle at 43.4% 31.86%, #ffffff 0, #ffffff 25%, #ffffff 50%, #cdcac5 85%, #d393f3 100%);
  --bm: linear-gradient( 75deg, hsl(40deg 82% 98%) 0%, hsl(100deg 47% 93%) 19%, hsl(116deg 47% 89%) 37%, hsl(122deg 39% 88%) 52%, hsl(154deg 12% 92%) 64%, hsl(280deg 82% 96%) 69%);
} */

/* minimal */
/* :root {
  font-size: clamp(0.75rem, 2vw, 1.5rem);
  --poppins-font: "Oswald";
  --josefin-font: "Tektur";
  --nothing-font: "Tektur";
  --white: #131312;
  --light-gray: #2e2e2d;
  --whitish-gray: #3d3d3c;
 
  --light-black-top: rgb(249 250 250);

  --pizza-yellow: #2b2b2a;
  --yellow: #cd0275;
  --green: #009798;
 
  --modal-glass-bg: rgba(255, 255, 255, 0.62);
 
  --transparent-button: #d73290;
  --bi: radial-gradient(circle at 50% 50%, #FFFCFE 0, #ffffff 75%, #e7e6e4 100%);
 
} */

/* ultra-minimal */
:root {
  font-family: var(--poppins-font);
    --poppins-font: 'NT Somic1', Arial, sans-serif;
    --josefin-font: 'NT Somic2', Arial, sans-serif;
    --nothing-font: 'NT Somic3', Arial, sans-serif;
    letter-spacing: 0.15rem;
    --white: #ffffff;
    --light-gray: #e4e2e3;
    --black: #202020;
    --whitish-gray: #605e5e;
    --whitish: #ffff;
    --light-black-top: #333132;
    --pizza-yellow: #605e5e;
    --yellow: #605e5e;
    --green: #605e5e;
    --modal-glass-bg: #ffffffcf;
    --transparent-button: #605e5e;
    --pinked: #d4a0a2;
    --bi: radial-gradient(circle at 50% 50%, #fffcfe 0, #fff 75%, #f3f3f2 100%);
    font-size: clamp(.75rem, 2vw, 1.2rem);
  /* --bm: linear-gradient( 75deg, hsl(40deg 82% 98%) 0%, hsl(100deg 47% 93%) 19%, hsl(116deg 47% 89%) 37%, hsl(122deg 39% 88%) 52%, hsl(154deg 12% 92%) 64%, hsl(280deg 82% 96%) 69%); */
}

/* Добавьте объявления для шрифта NT Somic */
@font-face {
  font-family: 'NT Somic1';
  src: url('../assets/images/Webfonts/NTSomic-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NT Somic2';
  src: url('../assets/images/Webfonts/NTSomic-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NT Somic3';
  src: url('../assets/images/Webfonts/NTSomic-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

     /* ligtcontr */
     /* :root {
      font-size: clamp(0.75rem, 2vw, 1.25rem);
    --poppins-font: "Oswald";
    --josefin-font: "Rubik Doodle Shadow";
    --nothing-font: "Rubik Broken Fax";
    --white: #000000;
    --light-gray: #20293f;
    --whitish-gray: #4c2f43;
    --light-black: rgb(220 134 27 / 68%);
    --lightish-black: rgb(185 45 81 / 68%);
    --pizza-yellow: #b92d51;
    --yellow: #784350;
    --green: #46d2ae;
    --pizza-red: #2479e3;
    --pizza-red-light: #c7503b 0;
    --transition: all ease-in-out 0.3s;
    --red: #b92d51;
    --modal-glass-bg: rgb(88 169 158 / 62%);
    --modal-box-shadow: -2px 10px 24px -11px #0000009e;
    --transparent-button: #e0ded8;
    --bi: linear-gradient( 110deg, hsl(184deg 81% 73%) 0%, hsl(106deg 38% 81%) 19%, hsl(49deg 71% 71%) 37%, hsl(43deg 92% 73%) 52%, hsl(40deg 91% 83%) 64%, hsl(33deg 74% 91%) 69%);
    --bm: radial-gradient(circle at 50% -20.71%, #ffffff 0, #ffe3ff 25%, #d4bbff 50%, #a795e4 75%, #7d77ce 100%);
    } */


/* :root {
  font-size: clamp(0.75rem, 2vw, 1.25rem); 
  --poppins-font: "Oswald";
  --josefin-font: "Rubik Doodle Shadow";
  --nothing-font: "Rubik Broken Fax";
  --white: #e4dfd5;
  
  --light-gray: #f1ece1;
  --whitish-gray: #cdc8c0;
  --light-black: rgba(11, 38, 55, 0.75);
  --lightish-black: rgb(67, 32, 62, 0.55);
  --pizza-yellow: #fab102;
  --yellow: #f9d805;
  --green: #6a8050;
  --pizza-red: #fd2026;
  --pizza-red-light: #fb33a3 0;
  --transition: all ease-in-out 0.3s;
  --red: #ff2657;
  --modal-glass-bg: rgba(255, 255, 255, 0.15);
  --modal-box-shadow: -2px 10px 24px -11px #0000009e;
  --transparent-button: #fcfa9e;
} */

/* :root {
  font-size: clamp(0.75rem, 2vw, 1.25rem); 
  --poppins-font: "Oswald";
  --josefin-font: "Rubik Doodle Shadow";
  --nothing-font: "Rubik Broken Fax";
  --white: #e4dfd5;
  
  --light-gray: #f1ece1;
  --whitish-gray: #cdc8c0;
  --light-black: #29242b;
  --lightish-black: #302735;
  --pizza-yellow: #fab102;
  --yellow: #f9d805;
  --green: #6a8050;
  --pizza-red: #fd2026;
  --pizza-red-light: #fb33a3 0;
  --transition: all ease-in-out 0.3s;
  --red: #ff2657;
  --modal-glass-bg: rgba(255, 255, 255, 0.15);
  --modal-box-shadow: -2px 10px 24px -11px #0000009e;
  --transparent-button: #f3bfc1;
} */

/* Reset */
html {
  /* font-size: 100%; */
  
  background-color: var(--light-black);
}

* {
  margin: 0;
  padding: 0;
}

body {
  
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  /* scrollbar-width: none; for Firefox */
  overflow-y: scroll;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: var(--bi);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow-x: hidden;
  padding-top: 0;
  letter-spacing: 0.15rem;
  /* overflow: hidden; */
}

body::after {
  content: "";
  display: block;
  height: 15vh; /* Высота, равная высоте области просмотра */
  width: 100%;
  background-color: var(--light-black-top); /* Цвет фона, как у footer */
  position: relative;
  bottom: 0;
  z-index: -1; /* Чтобы псевдоэлемент был под footer */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

img {
  display: block;
}

/* Flex */
.flex-container {
  display: flex;
}

.leaflet-bottom
{
 visibility: hidden;
}

/* Flex Row */
.flex-row {
  flex-direction: row;
}

bg {
  width:100vw;
  height: 100vw;
}
plan {
  width:90vw;
  height: 100vw;
}

/* Flex Column */
.flex-column {
  flex-direction: column;
}
/* Text Center */
.txt-center {
  text-align: center;
}

.react-dadata__input
{
  border: 1px solid transparent;
}

/* White */
.txt-white {
  color: var(--white);
  font-family: var(--poppins-font);
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.3); */
}

/* Poppins Font */
.pop-font {
  font-family: var(--poppins-font);
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.3); */
}




/* Стили для иконки корзины */
.cart-icon-container {
  position: relative;
  display: inline-block;
  margin-right: 20px; /* Добавляем отступ справа */
  height: 100%;
  animation: pulse 1.5s infinite; /* Добавляем анимацию пульсации */
}

.cart-icon-container:hover {
  transform: scale(1.1); /* Увеличиваем иконку при наведении */
  filter: brightness(1.2) drop-shadow(0 0 5px var(--pinked)); /* Добавляем свечение */
}

.cart-icon-container:active {
  transform: scale(0.95); /* Немного уменьшаем иконку при нажатии */
  filter: brightness(0.8) drop-shadow(0 0 2px var(--pinked)); /* Уменьшаем свечение при нажатии */
}

.cart-icon {
  height: 5vh; /* Уменьшаем размер иконки */
  width: 4rem;
  display: block;
  filter: drop-shadow(0 0 1px white); 
}

/* Стили для счетчика товаров */
.cart-badge {
  position: absolute;
  top: 3.5vh; /* Позиционируем над иконкой */
  right: 0vh; /* Сдвигаем вправо */
  background-color: var(--pinked); /* Розовый фон */
  color: var(--white); /* Белый текст */
  border-radius: 50%; /* Круглая форма */
  padding: 2px 6px; /* Отступы */
  font-size: 1.2rem; /* Размер шрифта */
  font-weight: bold;
  line-height: 1; /* Выравнивание по высоте */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px; /* Минимальная ширина */
}

/* Анимация пульсации */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.active-button-style {
  cursor: pointer;
  border-radius: 30px;
  /* border-style: solid; */
  border-width: 1px;
  padding: 0.5rem 1.2rem;
  /* opacity: 0.8; */
  /* background-color: var(--pinked); */
  border: 2px solid var(--pinked);
  letter-spacing: 1px;
  align-items: center;
  width: max-content;
  color: var(--black);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--josefin-font);
}
.passive-button-style {
  color: var(--light-gray);
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid var(--whitish);
  cursor: pointer;
  padding: 0.8rem 2rem;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-family: var(--josefin-font);
  font-weight: 400;
  min-width: 200px;
  justify-content: center;
  /* text-shadow: 2px 2px 8px rgba(0,0,0,0.4); */
  border-color: var(--light-gray);
}

.active-add-to-cart{
  border-color: var(--pinked);
  color: var(--black);
}

.button-arrow {
  font-size: 1.4rem;
}

.passive-button-style:hover {
  background-color: var(--pinked);
  /* color: var(--light-black-top); */
  /* border: 2px solid var(--green); */
}

.amination {
 
  align-items: center;
  width: 80%;
 
}

.section-description {
  color: var(--light-gray);
  font-family: var(--poppins-font);
  font-size: 1rem;
  text-align: center;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}
/* Header */

header {
  height: 5vh;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 2vh;
  /* background: rgba(0, 0, 0, 0.3); */
  /* backdrop-filter: blur(5px); */
}
.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* Logo */
.logo-styling {
  align-items: center;
  gap: 1rem;
  width: 5rem;
  height: 3rem;
  
}
.logo {
  width: 4rem;
  height: auto;
  max-width: 90%;
  filter: drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 1px var(--white)) drop-shadow(0 0 1px var(--white));

  transition: all ease-in-out 0.2s;
  
}
.logo-styling h1 {
  font-size: 2rem;
  font-family: var(--josefin-font);
  line-height: 1.7rem;
  letter-spacing: 1.2rem;
}
.logo-styling h1 span {
  display: block;
  color: var(--pizza-yellow);
  font-size: 1.0rem;
  letter-spacing: 0.1rem;
}

/* NAVIGATION MENU */
.navigation-menu {
  display: none;
  gap: 1.5rem;
  font-weight: 400;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
}

.navigation-menu a {
  color: #000;
  transition: all ease-in-out 0.2s;
}
/* Login & Cart */
.login-and-cart {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

canvas {
  height:100%;
  /* filter:Gray(); */
  opacity:0.2;
  resize: vertical;
}

.cart-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 600;
}
.cart-btn img {
  width: 1.5rem;
  height: 1.5rem;
}

.burger-bars {
  width: 3.3rem;
  cursor: pointer;
  margin-right: 1.6rem;
  padding-right: 1vh;
  position: relative;
  z-index: 100;
  mix-blend-mode: difference;
  filter: invert(1);
}

.burger-bars.light {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(295deg) brightness(87%) contrast(97%);
}

/* На прозрачном/светлом header (когда он в начале страницы) */
header:not([style*="background"]) .burger-bars {
  filter: brightness(0) saturate(100%) invert(80%) sepia(13%) saturate(604%) hue-rotate(309deg) brightness(86%) contrast(105%);
}

/* На темном header (когда появляется темный фон при скролле) */
header[style*="background"] .burger-bars {
  filter:  brightness(0) saturate(100%) invert(100%) sepia(93%) saturate(4012%) hue-rotate(287deg) brightness(95%) contrast(76%); /* белый */
}

.main-nav .navigation-menu.active .burger-bars {
  content: url("../assets//images/close-btn.svg");
  position: fixed;
  top: 2rem;
  right: 2rem;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(295deg) brightness(87%) contrast(97%);
}

.header-info span {
  color: #d75cfd;
  font-family: var(--nothing-font);
  font-size: 4rem;
  /* align-items: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-info h1 {
  text-transform: uppercase;
  font-size: 2.2rem;
  line-height: 2rem;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.6);
  color: white;
}

.header-info p {
  font-size: 1.6rem;
  color: white;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.6);
}

.header-btns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-btns a:first-child {
  /* color: var(--light-black); */
  /* background-color: var(--yellow);
  border: 1px solid transparent; */
}

.header-btns a:last-child {
     /* border-width: 1px; */
    /* border-color: var(--green); */
    /* background-color: var(--green); */
    /* background-color: var(--modal-glass-bg); */
    color: var(--white);
    border: 2px solid var(--green);
}
.overflow-hidden {
  overflow-x: hidden;
}

.contact-us-landing {
  /* position: relative; */
  overflow-x: hidden;
  padding: 4rem;
  /* max-width: 1440px; */
  /* width: 100%; */
  overflow-x: hidden;
}

.company-details2 {
  gap: 1.5rem;
  text-align: left;
  justify-content: space-between;
  font-family: var(--poppins-font);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  min-height: calc(97vh - 16rem); /* Adjust 4rem based on your footer's height */
 
}

.company-details {
  gap: 1.5rem;
  text-align: left;
  justify-content: space-between;
  font-family: var(--poppins-font);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  /* max-width: 500px; */
}

.company-details > * {
  text-align: center;
}

.company-details h2 {
  font-family: var(--poppins-font);
  font-weight: 400;
}

.company-details p {
  color: var(--light-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1.2rem;
}

/* Section 2 */
.section-two-img {
  width: 100%;
  object-fit: cover;
  max-height: 20rem;
  height: 50%;
  z-index: 2;
  opacity: 0.8;
}
.welcome-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  padding-top: 13rem;
  padding-bottom: 5rem;
  max-width: 1440px;
  margin: 0 auto;
  gap: 3rem;
}
.section-2-info {
  max-width: 800px;
  margin: 0 auto;
}
.section-2-info h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: var(--nothing-font);
  color: var(--black);
  /* text-transform: uppercase; */
}
.section-2-info p {
  font-size: 1.0rem;
  /* line-height: 1.8; */
  color: var(--black);
  text-align: center;
}
.section-two-img {
  width: 100%;
  max-width: 1200px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .welcome-section {
    padding: 2rem 1rem;
    padding-top: 13rem;
    padding-bottom: 5rem;
  }
  
  .section-2-info h2 {
    font-size: 1rem;
  }
  
  .section-2-info p {
    font-size: 1rem;
  }
}

/* Section 3 */

.section-3-container {
  text-align: center;
  /* background-color: var(--modal-glass-bg); */
  color: var(--white);
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* gap: 3rem; */
  padding: 6rem 0rem;
  max-width: 1440px;
  margin: 0 auto;
}
.section-3-container h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: var(--josefin-font);
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}
.section-3-container p {
  color: var(--light-gray);
  max-width: 1200px;
}

.services-grid {
  gap: 4rem;
  margin-top: 8rem;
  margin: 0 auto;
}

.services-grid .service-list {
  justify-content: center;
  position: relative;
  z-index: 1;
}
.service-list section {
  background-color: var(--lightish-black);
  padding: 1rem;
  position: absolute;
  top: 70%;
  z-index: 2;
  border-radius: 5px;
}

.services-grid img {
  cursor: pointer;
  object-fit: cover;
  padding: 1rem;
  max-width: 23rem;
  min-width: 15rem;
  width: 100%;
  height: 17rem;
  border: 1px solid transparent;
  margin: 0 auto;
  z-index: 2;
  opacity: 0.8;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translate3d(42px, -62px);
  transform-style: preserve-3d;
}

.services-grid h3 {
  font-size: 1.5rem;
  font-weight: 400;
}
/* Section 4 */
.section-4 {
  gap: 2rem;
  padding-bottom: 3rem;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* background-color:var(--modal-glass-bg); */
}
.section-4 .active-button-style {
  margin: 0 auto;
}
.section-4-info {
  padding: 2rem;
}
.section-4-info h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: var(--josefin-font);
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}
.section-4-info p {
  color: var(--light-gray);
  max-width: 1200px;
}

.meals-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  overflow-y: hidden;
  width: 90%;
  margin: 0 auto;
  
}

.meal-item {
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  padding: 1rem;
  background-color: var(--light-black);
  border: 4px solid transparent;
}
.meal-item h3 {
  font-weight: 400;
}
.meal-item img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.meal-item-details {
  gap: 0.5rem;
}
.meal-item-details p {
  color: var(--light-gray);
}

.meal-item-order {
  gap: 1rem;

  align-items: center;
}

.meal-item-order p {
  color: var(--pizza-yellow);
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
    font-weight: 700;
}

.meal-item-order a {
  border: solid 1px var(--white);
  padding: 0.5rem 1rem;
  letter-spacing: 1.5px;
  letter-spacing: 1.5px;
}

.section-9 .active-button-style {
  margin: 0 auto;
}
/* Section 5 */
.section-5 {
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
.section-5 h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: var(--josefin-font);
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}

.section-5 p {
  color: var(--light-gray);
  max-width: 1200px;
}
.pricing-grid {
  gap: 2.5rem;
  max-width: 1440px;
  margin: 0 auto;
}

.pricing-grid-item {
  gap: 1rem;
}
.pricing-details {
  width: 100%;
}
.pricing-details p {
  color: var(--light-gray);
  text-align: left;
}
.name-and-price {
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  font-size: 1rem;
  position: relative;
  background-color: var(--light-black);
}
.name-and-price::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  border-bottom: 1px solid var(--yellow);
  height: 1px;
}
.name-and-price h3 {
  position: relative;
  color: var(--whitish-gray);
  font-weight: 400;
}

.name-and-price p {
  color: var(--pizza-yellow);
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
    font-weight: 700;
}
.pricing-img {
  object-fit: cover;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}

/* Section 6 */

.gallery img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 15rem;
}

/* Section 7 */
.section-7 {
  position: relative;
  background-image: url("../assets/images/section-7-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 1rem;
  padding: 2rem;
}
.section-7::after {
  content: "";
  position: absolute;
  background-color: var(--modal-glass-bg);
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.highlights {
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  gap: 3rem;
}

.highligh-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.highlights img {
  margin: 0 auto;
  border: 2px solid var(--yellow);
  padding: 0.7rem;
  cursor: pointer;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 3rem;
  height: auto;
}

.highlights h3 {
  color: var(--yellow);
  font-family: var(--poppins-font);
  font-size: 2rem;
}
.highlights p {
  font-weight: 600;
  font-size: 1.5rem;
}

/* Section 8 */
.section-8 {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
  overflow: hidden;
}
.menu-slider-hero {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
}
/* Hero Video */
.hero-section {
  height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 0;
}

.hero-section_i {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient( */
    /* to bottom, */
    /* rgba(0, 0, 0, 0.7) 0%, */
    /* rgba(0, 0, 0, 0.5) 50%, */
    /* rgba(0, 0, 0, 0.7) 100% */
  /* ); */
  z-index: 2;
}

.header-info {
  position: relative;
  z-index: 3;
  gap: 2rem;
}

.header-info h1 {
  font-family: var(--josefin-font);
  font-size: clamp(3rem, 8vw, 6rem);
  line-height: 1.1;
  letter-spacing: 0.2em;
  color: var(--whitish);
  text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
}

.header-info .subtitle {
  font-size: clamp(1.2rem, 3vw, 2rem);
  color: var(--whitish);
  margin-top: 3rem;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
}

.header-btns {
  margin-top: 2rem;
}

.passive-button-style {
  font-size: clamp(1rem, 2vw, 1.5rem);
  padding: 1rem 3rem;
  border-width: 2px;
}

@media (max-width: 768px) {
  .header-info {
    padding: 0 1rem;
  }
  header {
    padding: 1vh;
  }
  
  .hero-section {
    padding-top: 7vh;
  }
}

.iframec {
  height: 0px;
  width: 0px ;
  border: 0px;
  visibility: hidden;
  
  
}

.dish-slider .dish-categories {
  margin: 0 auto;
  gap: 1rem;
  max-width: 100%;
}
.dish-categories ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  
}
.dish-categories button {
  /* border: 2px solid var(--yellow); */
  
  padding: 0.5rem 1rem;
  /* color: var(--yellow); */
  font-family: var(--josefin-font);
  font-size: 1.5rem;
  list-style: none;
  cursor: pointer;
  border-radius: 30px;
  background-color: var(--light-black-top);
  border: 2px solid var(--light-black-top);
  text-transform: capitalize;
  transition: all ease-in-out 0.2s;
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}

.dish-categories button:hover {
  background-color: var(--yellow);
  color: var(--light-black-top);
  transition: all ease-in-out 0.2s;
}

/* Dish Details */
.dish-slider {
  gap: 3rem;
}

.menu-slider-products {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  width: 90%;
}

/* SLIDER PRODUCT */
.slider-product {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.slider-product img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  height: 10rem;
  min-height: 30%;
}

.slider-product h3 {
  font-weight: 400;
}
.slider-product-description {
  width: 15rem;
  max-width: 100%;
  min-width: 70%;
  margin: 0 auto;
}
.dish-details-desc {
  color: var(--whitish-gray);
}

.dish-details-pricing {
  color: var(--pizza-yellow);
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.62); */
  font-weight: 700;
}

.slider-product a {
  border: 1px solid var(--whitish-gray);
  padding: 0.5rem;
  width: 40%;
  margin: 0 auto;
}

/* Section 9 */
.section-9 {
  max-width: 1440px;
  margin: 0 auto;
}

.section-9 h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}

/* Blog Posts */
.blog-posts-landing {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 1.5rem;
}
.blog-posts-landing .post {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
}
.blog-posts-landing .post h3 {
  transition: all ease-in-out 0.3s;
}

.blog-posts-landing h3 {
  font-weight: 500;
}
.blog-posts-landing img {
  max-width: 100%;
  max-height: 60%;
  object-fit: cover;
}

.blog-posts-landing .intro {
  gap: 1rem;
}

.blog-posts-landing .date,
.blog-posts-landing .intro {
  color: var(--whitish-gray);
}

/* Section 10 */
.section-10 {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 1rem;
  padding-bottom: 3rem;
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}

.section-10 h2 {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: left;
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}

.contact-emails {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  margin: 0 auto;
  overflow: hidden;
  font-family: var(--poppins-font);
  padding: 2rem;
}
.contact-emails h3 {
  font-size: 2rem;
  font-family: var(--josefin-font);
  color: var(--black);
}
.contact-emails h3,
.contact-emails h4 {
  color: var(--black);
  font-weight: 500;
  /* text-shadow: 2px 2px 40px rgba(248,27,226,1); */
}
.contact-emails p {
  color: var(--light-gray);
}
.contact-emails ul {
  color: var(--black);
  display: flex;
  flex-direction: column;
}
#map {
  height: 30rem;
  max-width: 90%;
  margin: 0 auto;
}

.section-10 .active-button-style {
  margin-top: 2rem;
}

.newsletter-success {
  color: var(--green);
  font-size: 1.5rem;
  line-height: 160px;
}

.email-subscribtion {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  min-width: 375px;
  max-width: 900px;
  color: var(--white);
  margin: 7rem auto;
  overflow: hidden;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.webflow-style-input {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-radius: 5px;
  opacity: 0.8;
  width: 100%;
  padding: 0.5rem 1.2rem;
  background-color: rgba(20, 20, 20, 0.672);
}
.webflow-style-input:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(to right, #f56456, #dfe657, #ee5e41, #ffff10, #b294ff, #57e6e6);
  background-size: 500% auto;
  -webkit-animation: gradient 3s linear infinite;
  animation: gradient 3s linear infinite;
}

.webflow-style-input input,
.webflow-style-input textarea {
  border-style: none;
  background: transparent;
  outline: none;
  color: var(--whitish-gray);
  flex-grow: 1;
  line-height: 2.4rem;
  vertical-align: middle;
  font-size: 1rem;
  font-family: var(--poppins-font);
}

.input-section {
  min-width: calc(40% + 5rem);
  max-width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.input-section form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.email-label {
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  font-size: 1.2rem;
  text-align: center;
  max-width: 650px;
}

.error-msg {
  font-family: var(--poppins-font);
}
/* Footer */
footer {
  background-color: var(--light-black-top);
  padding: 2rem 0;
  color: var(--whitish);
  margin-top: auto;
}

.footer-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 0 2rem;
  text-align: center;
}

.contact-info, .address-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.phones p {
  /* font-size: 1.2rem; */
  margin-bottom: 0.5rem;
  color: var(--white);
}

.working-hours p:first-child {
  margin-bottom: 0.5rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.social-links img {
  width: 2rem;
  height: 2rem;
  filter: brightness(0) invert(1);
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.social-links img:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .footer-contact {
    /* grid-template-columns: 1fr; */
    text-align: center;
  }
  
  .social-links {
    justify-content: center;
  }
}

.footer-menu {
  gap: 1rem;
  font-weight: 400;
  font-size: 1rem;
  align-items: center;
  padding-left: revert;
}

.footer-menu a {
  transition: all ease-in-out 0.2s;
  letter-spacing: 1.5px;
}

.footer-menu a:hover {
  color: var(--pizza-red);
  transition: all ease-in-out 0.2s;
}

.footer-contact {
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 2rem;
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.logo-styling.footer {
  margin: 0 auto;
  margin-top: 2rem;
}

hr {
  opacity: 0.2;
}

.accepted-payments {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 0 auto;
}
.accepted-payments img {
  width: 2rem;
  max-width: 100%;
}

/* Scroll Button */
/* Scroll Button */
.scroll-button {
  background-color: var(--pinked);
  border-radius: 50%;
  border: 2px solid var(--green);
  /* color: var(--transparent-button); */
  position: fixed;
  right: 10px; /* Начальное положение за пределами экрана */
  bottom: 150px;
  transition: right 0.5s ease-in-out; /* Анимация появления справа */
  cursor: pointer;
  /* font-size: 20px; */
  padding: 1.3rem;
  background-image: url("../assets/images/scroll-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  opacity: 0; /* Начальная прозрачность */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень для кнопки */
  z-index: 1000; /* Чтобы кнопка была поверх других элементов */
}

.scroll-button:hover {
  background-color: var(--green); /* Изменение цвета фона при наведении */
  border-color: var(--whitish); /* Изменение цвета границы при наведении */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Увеличение тени при наведении */
  transform: translateY(-2px); /* Небольшое поднятие кнопки при наведении */
}

.scroll-button.visible {
  right: 0.8rem; /* Конечное положение на экране */
  opacity: 0.7; /* Появление с увеличением прозрачности */
}

/* Transitions */
.navigation-menu a,
.logo,
.header-btns a:last-child,
.header-btns a:first-child,
.meal-item-order a,
.more-pizza,
.dish-categories h3,
.dish-details a,
.form-btn,
.txt-white,
.login-and-cart .cart-btn,
.active-button-style,
.passive-button-style {
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.active-button-style:hover {
  background-color: var(--pinked);
  color: var(--light-black-top);
  border: 2px solid var(--whitish);
  /* color: var(--white);
  border-color: var(--white); */
}
.passive-button-style:hover {
  background-color: var(--pinked);
  /* color: var(--light-black-top); */
  /* border: 2px solid var(--green); */
}

/* Hover */
@media (hover: hover) {
  .navigation-menu a:hover,
  .logo:hover,
  .header-btns a:last-child:hover,
  .header-btns a:first-child:hover,
  .meal-item-order a:hover,
  .more-pizza:hover,
  .dish-categories h3:hover,
  .dish-details a:hover,
  .form-btn:hover,
  .login-and-cart .cart-btn:hover,
  .active-button-style:hover,
  .passive-button-style:hover,
  .blog-posts-landing .post h3:hover {
    -moz-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    -webkit-transition: all ease-in-out 0.3s;
    -ms-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }

  .navigation-menu a:hover {
    color: var(--green);
  }
  .logo:hover {
    transform: rotate(360deg);
  }
  .blog-posts-landing .post h3:hover {
    color: var(--pizza-red);
  }

  .header-btns a:last-child:hover {
    /* color: var(--light-black); */
    color: var(--light-black-top);
    background-color: var(--pinked);
    border: 1px solid transparent;
  }
  .header-btns a:first-child:hover {
    border: 2px solid var(--green);
    /* color: var(--light-black);
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.637); */
    background-color: var(--pinked);
  }

  .meal-item-order a:hover {
    background-color: var(--yellow);
    border: 1px solid transparent;
    color: var(--light-black);
  }
  .more-pizza:hover {
    background-color: var(--yellow);
    color: var(--light-black);
    border: 1px solid transparent;
  }
  .dish-categories h3:hover,
  .dish-details a:hover {
    background-color: var(--yellow);
    color: var(--light-black);
  }
  .form-btn:hover {
    border: 1px solid var(--yellow);
    color: var(--yellow);
    background-color: transparent;
  }
  .login-and-cart a:hover {
    background-color: var(--green);
    border-color: var(--green);
    color: var(--whitish);
    /* opacity: 0.9; */
  }
}

.navigation-menu.active {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: rgb(239, 239, 239);
  padding: 6rem 0rem 0rem;
  height: 100vh;
  justify-content: flex-start;
  gap: 2rem;
}

/* Медиа-запрос для больших экранов (desktop) */
@media (min-width: 768px) {
  .navigation-menu.active {
    width: 38%; /* Ширина меню на десктопе */
  }
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .navigation-menu.active {
    width: 100%; /* Меню на всю ширину экрана */
    right: 0;
  }
}

.navigation-menu.active li {
  text-align: center;
}

.navigation-menu.active a {
  font-size: 1.6rem;
  color: #000000;
  /* font-weight: 400; */
  display: block;
  padding: 1rem;
}

.menu-footer {
 /* margin-top: auto; */
  text-align: center;
  padding: 2rem 0;
}

.menu-footer .address {
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-family: var(--josefin-font);
}

.menu-footer .phone {
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icon {
  width: 3rem;
  height: 3rem;
  background: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon img {
  width: 1.5rem;
  height: 1.5rem;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.socials span {
  display: none;
}

.socials img {
  max-width: 2rem;
  width: 100%;
  height: 100%;
}

.pizza-one {
  position: absolute;
  top: -12%;
  width: 20rem;
  opacity: 0.8;
  overflow: hidden;
}
.pizza-two {
  position: absolute;
  top: -12%;
  width: 20rem;
  opacity: 0.8;
  overflow: hidden;
}

.company-details-image {
  position: absolute;
  width: 22rem;
  opacity: 0.8;
  
  /* z-index: -1; */
  overflow: hidden;
}                                       
.company-details-image-two {        
  position: absolute;
  width: 22rem;
  opacity: 0.8;
  
    /* z-index: -1; */
  overflow: hidden;
}
.company-details-image-three {
  position: absolute;
  width: 22rem;
  opacity: 0.9;
  
  /* z-index: -1; */
  overflow: hidden;
}

.pizza-one {
  display: none;
}

.company-details-image {
  display: none;
}

.company-details-image-two {
  display: none;
}

.company-details-image-three {
  display: none;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
}
.terms h2 {
  font-size: 3rem;
  color: white;
  text-align: center;
  font-family: var(--poppins-font);
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}
.terms p,
.terms h3 {
  color: var(--light-gray);
  /* text-shadow: 0 2px 40px rgba(248,27,226,1); */
}

.nav-right-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.main-nav {
  position: relative;
}

.success-msg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--green);
  color: var(--whitish);
  padding: 1rem 2rem;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.success-msg.visible {
  opacity: 1;
  visibility: visible;
}

/* Dish Carousel Section */
.dish-carousel-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 4rem 2rem;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 10rem;
}

.dish-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;
}

.carousel-slides {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  height: 400px;
  position: relative;
}

.slide {
  position: relative;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center center;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 36px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Стили для предыдущего слайда */
.prev-slide {
  /* width: 25%; */
  opacity: 0.7;
  filter: brightness(0.8);
  transform: translateX(-68%) scale(0.85);
  position: absolute;
  left: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Стили для текущего слайда */
.current-slide {
  width: 45%;
  z-index: 2;
  transform: scale(1);
  position: relative;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Стили для следующего слайда */
.next-slide {
  /* width: 25%; */
  opacity: 0.7;
  filter: brightness(0.8);
  transform: translateX(68%) scale(0.85);
  position: absolute;
  right: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Стили для кнопок */
.carousel-button {
  background: none;
  /* color: white; */
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 3;
}

.carousel-button:hover {
  background: var(--green);
  transform: scale(1.1);
}

.carousel-button svg {
  width: 20px;
  height: 20px;
}

/* Анимация перехода */
.current-slide.transitioning {
  transform: scale(0.95);
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .carousel-slides {
    height: 300px;
  }
  
  .prev-slide,
  .next-slide {
    display: none;
  }
  
  .current-slide {
    width: 90%;
    margin: 0 auto;
  }
  
  .dish-carousel-container {
    padding: 0 1rem;
  }
}

/* Стили для заголовка времени работы */
.working-hours {
  text-align: center;
  margin-bottom: 2rem;
}

.working-hours-title {
  color: #d7a3a5;
  font-family: var(--josefin-font);
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  letter-spacing: 0.15em;
}

.working-hours-schedule {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
}

.working-hours-schedule p {
  /* color: var(--whitish); */
  font-family: var(--poppins-font);
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.05em;
}

/* Стили для кнопки "Смотреть меню" */
.menu-button {
  background-color: #d7a3a5;
  color: white;
  padding: 15px 40px;
  border-radius: 50px;
  font-family: var(--josefin-font);
  font-size: 1.2rem;
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  text-align: center;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-button:hover {
  background-color: #B39485;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Анимации для переходов */
.slide.transitioning.prev {
  transform: translateX(-68%) scale(0.85) rotate(-5deg);
}

.slide.transitioning.current {
  transform: scale(0.95) rotate(5deg);
}

.slide.transitioning.next {
  transform: translateX(68%) scale(0.85) rotate(5deg);
}

/* Image Collage Styles */
.image-collage {
  /* width: 100%; */
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;
}

.collage-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
}

.collage-cell {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.collage-cell.appear {
  opacity: 1;
  transform: translateY(0);
}

/* Задержка анимации для каждой ячейки */
.collage-cell:nth-child(1) { transition-delay: 0.3s; }
.collage-cell:nth-child(2) { transition-delay: 0.6s; }
.collage-cell:nth-child(3) { transition-delay: 0.9s; }
.collage-cell:nth-child(4) { transition-delay: 1.2s; }

.collage-cell img {
  width: 100%;
  object-fit: cover;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  filter: grayscale(20%);
}

.vertical-img {
  height: 60%;
}

.horizontal-img {
  height: 40%;
}

/* Эффект при наведении */
.hover-effect {
  transform: scale(1);
  transition: all 0.5s ease;
}

.hover-effect:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
  z-index: 1;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Медиа-запросы остаются прежними */
@media (max-width: 1024px) {
  .collage-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .collage-cell {
    height: 400px;
  }
}

/* @media (max-width: 768px) {
  .collage-grid {
    grid-template-columns: 1fr;
  }
  
  .collage-cell {
    height: 300px;
  }
  
  .image-collage {
    padding: 1rem;
  }
} */

.grayscale-tiles {
  filter: grayscale(100%) brightness(95%) contrast(90%);
}

/* Добавляем медиа-запрос для скрытия меню на мобильных устройствах */
@media (max-width: 768px) {
  .footer-menu {
    display: none;
  }
}

/* Добавьте объявления для шрифта NT Somic */
@font-face {
  font-family: 'NT Somic1';
  src: url('../assets/images/Webfonts/NTSomic-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NT Somic2';
  src: url('../assets/images/Webfonts/NTSomic-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NT Somic3';
  src: url('../assets/images/Webfonts/NTSomic-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Измените переменные шрифтов в :root */
:root {
  --poppins-font: 'NT Somic1', Arial, sans-serif;
  --josefin-font: 'NT Somic2', Arial, sans-serif;
  --nothing-font: 'NT Somic3', Arial, sans-serif;
  /* ... остальные переменные ... */
}

/* Базовые стили для всех кнопок */
.button-base {
  cursor: pointer;
  border-radius: 30px;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-family: var(--poppins-font);
  font-weight: 400;
  letter-spacing: 0.15rem;
  width: 14rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

/* Эффект ripple для всех кнопок */
.button-base::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
  transition: width 0.6s ease-out, height 0.6s ease-out;
}

.button-base:active::after {
  width: 300px;
  height: 300px;
}

/* Основная кнопка */
.button-primary {
  background-color: var(--pinked);
  color: var(--black);
  border: 2px solid var(--pinked);
  box-shadow: 0 2px 8px rgba(212, 160, 162, 0.2);
}

.button-primary:hover {
  transform: translateY(-2px) scale(1.01);
  box-shadow: 0 4px 12px rgba(212, 160, 162, 0.3);
  background-color: #dba7a9;
}

.button-primary:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(212, 160, 162, 0.2);
}

/* Второстепенная кнопка */
.button-secondary {
  background-color: transparent;
  color: var(--whitish-gray);
  border: 2px solid var(--pinked);
  /* backdrop-filter: blur(5px); */
}

.button-secondary:hover {
  background-color: rgba(96, 94, 94, 0.1);
  transform: translateY(-2px);
  border-color: #8a8888;
}

.button-secondary:active {
  transform: translateY(1px);
}

/* Отключенная кнопка */
.button-disabled {
  background-color: #f0f0f0;
  color: #a8a8a8;
  border: 2px solid #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
  transform: none !important;
  box-shadow: none !important;
}

/* Анимация загрузки */
@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button-loading {
  position: relative;
  color: transparent !important;
}

.button-loading::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: loading 0.8s ease infinite;
}

/* Эффект успешного действия */
.button-success {
  background-color: #a8d8a8 !important;
  border-color: #a8d8a8 !important;
  color: var(--black) !important;
  transition: all 0.3s ease;
}

/* Эффект ошибки */
.button-error {
  background-color: #ffb2b2 !important;
  border-color: #ffb2b2 !important;
  color: var(--black) !important;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}

/* Группа кнопок */
.button-group {
  display: flex;
  gap: 1rem;
}

.button-group .button-base {
  min-width: auto;
}
